/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 17:34:06
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-20 16:29:49
 */
import { createNamespacedHelpers } from 'vuex'
import ChartsList from './components/chartsList'
import TableList from './components/tableList'

const { mapGetters } = createNamespacedHelpers('App/Datapackage/Crowdattribute')

export default {
  name: 'PageMain',
  components: {
    ChartsList,
    TableList
  },
  computed: {
    // 当前展示列表还是图表
    ...mapGetters([
      'activeTab',
      'queryForm'
    ])
  },
  data () {
    return {}
  }
}
