/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 17:37:46
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 17:42:50
 */
import TableList from './tableList.vue'
export default TableList
