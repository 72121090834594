/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 17:33:30
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 17:34:58
 */
import PageMain from './pageMain.vue'
export default PageMain
